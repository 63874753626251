/* General Styles */
.timesheet-container {
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    transition: all 0.3s ease; /* Smooth transitions */
}

h2, h3 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 1.8em;
    font-weight: 600; /* Bolder text for emphasis */
}

.current-date {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    color: #007bff;
    font-size: 1.2em;
}

.error-message {
    color: #ff4d4d;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
}

/* Fieldset Styles - Elevated and Spaced */
fieldset {
    border: none;
    margin: 20px 0; /* Reduced margin for a compact layout */
    padding: 0;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    overflow: hidden; /* Hide content when collapsed */
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}

fieldset.collapsed {
    max-height: 40px; /* Only show the legend when collapsed */
    padding: 0;
}

/* Stylish Legends with Plus/Minus Icons */
legend {
    font-weight: bold;
    font-size: 1.2em;
    padding: 10px; /* Increased padding for better click area */
    color: #007bff;
    text-align: left;
    cursor: pointer;
    background-color: #f1f1f1;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease; /* Smooth hover effect */
}

legend:hover {
    background-color: #e0e0e0; /* Darker background on hover */
}

/* Fieldset Content Styling (Hidden/Shown) */
.fieldset-content {
    padding: 20px 30px;
    background-color: #ffffff;
    border-top: 1px solid #ddd;
}

fieldset.collapsed .fieldset-content {
    display: none;
}

label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
    color: #333;
    font-size: 1em;
}

/* Date Picker Group */
.date-picker-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.date-picker label {
    margin-bottom: 5px;
}

.date-picker input {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    font-size: 1em;
}

/* Ensure date picker displays correctly */
.react-datepicker {
    z-index: 1000; /* Ensure it's above other elements */
}

/* Time Log Fieldset */
.log-time-fieldset {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.date-range-fieldset {
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Timesheet Entries Fieldset */
.timesheet-entries-fieldset {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0; /* Remove extra padding for better alignment */
    overflow: hidden; /* Prevent overflow from fieldset */
}

/* Scrollable Table Container */
.table-container {
    max-height: 300px; /* Set a max height for scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    margin-top: 20px; /* Space above the table */
}

/* Table Styles */
table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box; /* Include padding and border in width */
}

table th, table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

table th {
    background-color: #007bff;
    color: white;
    font-size: 1em;
}

table td {
    background-color: #f8f8f8;
    font-size: 0.95em;
}

table th, table td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Total Hours Display */
.total-hours {
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    font-size: 1.2em;
}

/* Button Styles */
.small-button {
    padding: 8px 14px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.small-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.button-group, .log-time-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .timesheet-container {
        padding: 15px;
    }

    h2, h3 {
        font-size: 1.5em;
    }

    .small-button {
        width: 100%;
    }

    .log-time-fieldset {
        flex-direction: column;
    }

    .date-picker-group {
        flex-direction: column;
    }

    .table-container {
        max-height: 300px; /* Keeps the max-height for mobile */
        overflow-y: auto; /* Allows for scrolling on smaller screens */
    }

    table {
        display: block; /* Allows the table to be responsive */
        width: 100%;
        overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
    }

    table th, table td {
        font-size: 0.85em;
    }
}

@media (max-width: 480px) {
    h2, h3 {
        font-size: 1.3em;
    }

    .date-picker-group, .button-group, .log-time-buttons {
        flex-direction: column;
        gap: 10px;
    }

    table th, table td {
        padding: 8px;
    }

    .total-hours {
        font-size: 1em;
    }
}
.time-input-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa; /* Light background for contrast */
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
}

.time-input-group label {
    font-size: 0.9rem;
    color: #333;
    margin-right: 5px;
}

.time-input-group select {
    padding: 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    outline: none;
    transition: border-color 0.3s ease;
}

.time-input-group select:focus {
    border-color: #007bff; /* Highlight color on focus */
}

.time-input-group button {
    padding: 8px 12px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.time-input-group button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}
.delete-emoji {
    cursor: pointer;
    font-size: 1.2em; /* Adjust size as needed */
    color: red; /* Color the emoji red to indicate delete */
}
.delete-emoji:hover {
    color: darkred; /* Optional: Darker shade on hover for feedback */
}