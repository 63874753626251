/* Center the login form */
.login-container {
    background-color: #f8f9fa;
}

/* Style the card */
.login-card {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

/* Form styling */
.form-control {
    border-radius: 5px;
    border: 1px solid #ced4da;
    font-size: 1rem;
    padding: 10px;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-size: 1rem;
    padding: 10px;
}

h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #343a40;
}