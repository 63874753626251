/* Custom Header Styles */
.header {
    background-color: #0D6EFD; /* Bootstrap primary color */
    color: white;
    padding: 10px 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.header__brand {
    flex: 1; /* Allow brand to take up available space */
}

.header__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap; /* Wrap items in the nav on smaller screens */
}

.header__item {
    margin: 0 10px; /* Add horizontal spacing */
}

.header__link {
    color: white;
    text-decoration: none;
}

.header__link:hover {
    text-decoration: underline; /* Add hover effect */
}

.header__btn {
    background-color: white; /* Button background color */
    color: #007bff; /* Button text color */
    border: none;
    padding: 8px 12px; /* Increase padding for buttons */
    border-radius: 5px; /* Add rounded corners */
    cursor: pointer;
    margin-left: 10px; /* Margin for button */
    transition: background-color 0.3s; /* Smooth background change */
}

.header__btn:hover {
    background-color: #e7e7e7; /* Button hover effect */
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .header {
        padding: 15px;
    }

    .header__nav {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the start */
    }

    .header__list {
        flex-direction: column; /* Stack items vertically */
        width: 100%; /* Full width for the list */
    }

    .header__item {
        margin: 5px 0; /* Add vertical spacing */
        width: 100%; /* Ensure full width for items */
        text-align: center; /* Center text */
    }

    .header__btn {
        width: 100%; /* Full width for buttons on small screens */
        margin-top: 10px; /* Add spacing above buttons */
    }
}
