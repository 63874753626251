.admin-dashboard {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2, h3 {
    text-align: center;
    color: #333;
}

.user-selection {
    margin-bottom: 20px;
}

.user-selection label {
    margin-right: 10px;
}

select {
    padding: 8px;
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.date-range-picker {
    margin: 30px 0;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.date-picker {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.date-picker input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: fit-content;
}

button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

button:hover {
    background-color: #0056b3;
}

.timesheet-details {
    margin: 20px 0;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.timesheet-details table {
    width: 100%;
    border-collapse: collapse;
}

.timesheet-details th, .timesheet-details td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: left;
}

.timesheet-details th {
    background-color: #f2f2f2;
    color: #333;
}

.timesheet-summary {
    margin-top: 20px;
    background-color: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.add-user-form {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin: 30px 0;
}

.add-user-form input, .add-user-form select {
    display: block;
    width: calc(100% - 20px);
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.add-user-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

.add-user-form button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    text-align: center;
    margin-top: 10px;
}

.success-message {
    color: green;
    text-align: center;
    margin-top: 10px;
}

/* Responsive design */
@media (max-width: 600px) {
    .admin-dashboard {
        padding: 10px;
    }

    .add-user-form input, .add-user-form select {
        width: 100%;
    }

    .date-picker {
        flex-direction: column;
    }

    .date-picker input {
        width: 100%;
    }
}
