.change-password {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

input[type="password"],
input[type="text"] { /* Ensure consistent styling for both text and password inputs */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

input[type="password"]:focus,
input[type="text"]:focus { /* Focus styles for both types */
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.toggle-visibility {
  position: absolute;
  right: 10px; /* Aligns the toggle button to the right */
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem; /* Size for visibility toggle */
  padding: 0px;
  margin: 0px;
}

.error {
  color: #d9534f;
  font-size: 14px;
  text-align: center;
}

.success {
  color: #5cb85c;
  font-size: 14px;
  text-align: center;
}
