/* Basic layout for the home page */
.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f0f0;
    padding: 20px;
}

/* Styling the animated title */
.animated-title {
    font-size: 2.5rem; /* Kept the size for visibility */
    font-weight: bold;
    margin-bottom: 20px;
    animation: fadeInZoom 2s ease-in-out, subtleGlow 3s infinite ease-in-out;
}

/* Styling the TimeWise title with a different color and additional effects */
.timewise-title {
    color: #007bff; /* Adjusted to a calmer blue */
    font-size: 3rem;
}

/* Basic styling for the intro text */
.intro-text {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: #555;
}

/* Styling for the login button */
.login-btn {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    padding: 12px 24px; /* Increased padding for a larger button */
    font-size: 1.2rem;
    border: none;
    border-radius: 5px; /* Rounded corners */
    text-decoration: none; /* No underline */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

/* Hover effect for the login button */
.login-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Keyframes for fade-in and zoom effect */
@keyframes fadeInZoom {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    50% {
        opacity: 0.5;
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Toned down glow effect */
@keyframes subtleGlow {
    0% {
        text-shadow: 0 0 2px #007bff, 0 0 4px #007bff;
    }
    50% {
        text-shadow: 0 0 4px #007bff, 0 0 6px #007bff; /* Reduced glow size */
    }
    100% {
        text-shadow: 0 0 2px #007bff, 0 0 4px #007bff;
    }
}
